.star-rating {
  .star-rating-description {
    text-align: center;
    font-size: 12px;
    opacity: 0;
    animation: fadeOut 0.25s;
  }

  &:hover {
    .star-rating-description {
      opacity: 1;
      animation: fadeIn 0.25s;
    }

    .star-rating-item {
      filter: grayscale(0%);
    }
  }

  .star-rating-item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.85rem;
    cursor: pointer;
    filter: grayscale(100%);
    width: 50px;
    height: 0px;

    &.active {
      font-size: 2.25rem;
      filter: grayscale(0%);
    }

    &.inactive {
      font-size: 1.85;
      filter: grayscale(100%);
    }
  }

  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  @keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }
}