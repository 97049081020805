@import "./fonts.scss";

html, body {
  width: 100%;
  min-height: 100vh;
  margin: 0;
  font-family: 'Aeonik', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

html, body, #root, #root>div {
  min-height: 100%;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6,
.form-control-label,
.font-weight-bold, .btn {
  font-weight: 500 !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6,
.form-control-label {
  color: $gray-800;
}

p {
  color: $gray-600 !important;
}

#root {
  min-height: 100vh;
}

.text-white {
  color: white;
}

.input-group-alternative {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15), 0 1px 0 rgba(0, 0, 0, 0.05) !important;
}

.input-group-alternative:focus-within {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
}

.carousel-item > img {
  @extend .card-img-top;
  width: 100%; 
}

hr {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

@media (min-width: 768px) {
  .content-wrapper {
    padding: 40px;
  }
}

@media (max-width: 767px) {
  .content-wrapper #label {
    padding-top: 20px;
  }
  .content-wrapper {
    padding: 10px;
  }
}

.bg-gray-200 {
  background: $gray-200;
}

.text-gray-100 {
  color: $gray-100;
}

.text-gray-200 {
  color: $gray-200;
}

.text-gray-300 {
  color: $gray-300;
}

.text-gray-400 {
  color: $gray-400;
}

.text-gray-500 {
  color: $gray-500;
}

.text-gray-600 {
  color: $gray-600;
}

.text-gray-700 {
  color: $gray-700;
}

.text-gray-800 {
  color: $gray-800;
}

.text-gray-900 {
  color: $gray-900;
}

.mh-100 {
  min-height: 100% !important;
}

.timeline::before {
  height: 100% !important;
}

a.text-white:hover, a.text-white:focus {
  color: white !important;
}

svg {
  vertical-align: sub;
}

.btn-icon-only {
  max-height: 2rem !important;
  max-width: 2rem !important;
}

.bg-gradient-primary {
  background: linear-gradient(87deg, rgb(80, 100, 216) 0%, rgb(153, 166, 236) 100%);
}

.bg-gradient-danger {
  background: linear-gradient(87deg,#f5365c,#f56036)!important;
}

.progress .bg-primary {
  background-color: #8a9aea !important;
}

.progress .bg-danger {
  background-color: #f76b6b !important;
}

.popover{
  min-width: 450px;
}

@media (max-width: 767px) {
  .popover{
    min-width: 90%;
  }
}

.rbc-time-gutter {
  margin-top: -12.5px;
  padding-right: 10px;
}

.rbc-time-content {
  padding-top: 0.5rem !important;
}

.rbc-timeslot-group {
  text-align: right;
  min-height: 75px;
  color: #8898aa;
  border-top: 1px solid #DDD;
  border-bottom: 0px !important;
}

.rbc-day-slot .rbc-event {
  border: none;
  border-left: 7.5px solid #94a4fb;
}

.rbc-event {
  background-color: #eceefb;
  padding: 10px;
  border-radius: 10px;
  position: static;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.2);
  overflow-y: auto;
}

.rbc-event-content h4 {
  color: #32325d !important;
}

.rbc-event-content h5 {
  color: #626eb3;
}

.rbc-event-label {
  display: none;
}

.rbc-time-content > * + * > * {
  border-left: 0 !important;
}

.rbc-time-gutter .rbc-timeslot-group,
.rbc-time-content,
.rbc-time-header-content,
.rbc-time-view {
  border: 0 !important;
}

.rbc-label {
  font-size: 0.9rem;
  font-weight: 500;
}

.rbc-today {
  background: inherit;
}

.rbc-current-time-indicator {
  background: $default !important;
  height: 2px;
}

.single-line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.rbc-day-slot .rbc-events-container {
  margin-right: 0px;
}

.rbc-event-disabled {
  cursor: not-allowed;
  background: repeating-linear-gradient(45deg,rgba(0,0,0,0),rgba(0,0,0,0) 8.5px,#fff 0,#fff 10px);
}

.rbc-day-bg + .rbc-day-bg {
  border-left: none;
}

.rbc-header {
  padding: 15px;
  border: 1px solid #DDD;
  color: #32325d;
  font-weight: 100;
}

.rbc-time-header-content {
  height: 100% !important;
}

.rbc-hide-time-indicator .rbc-current-time-indicator {
  display: none !important;
}

.datepicker-absolute .rdtPicker {
  position: absolute;
  z-index: 0 !important;
  top: 0px;
}

.scroll {
  max-height: 100vh;
  overflow-y: auto;
}

th {
  outline: none;
}

.react-bootstrap-table table {
  table-layout: auto !important;
}

.cursor-pointer {
  cursor: pointer;
}

.rbc-time-view-resources .rbc-time-gutter,
.rbc-time-view-resources .rbc-time-header-gutter, 
.rbc-header,
.rbc-time-header-content > .rbc-row.rbc-row-resource,
.rbc-day-slot .rbc-time-slot,
.rbc-time-header.rbc-overflowing {
  border: none !important;
}

.rbc-allday-cell {
  display: none;
}

.rbc-header {
  font-weight: 500;
  color: #8898aa;
}

.rbc-time-view-resources .rbc-day-slot {
  min-width: 125px !important;
}

.rbc-time-view-resources .rbc-header {
  width: 125px !important;
}

.rbc-event-content .badge {
  font-size: xx-small;
}

.rbc-slot-selection {
  background-color: rgb(204, 211, 247);
  border-left: 7.5px solid rgb(72, 95, 224) !important;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.2);
  padding: 10px;
  border-radius: 10px;
  font-size: 0.5rem;
  font-weight: 500;
  color: rgb(36, 42, 76);
}

.rbc-slot-selection {
  font-size: x-small !important;
  color: rgb(52, 60, 109) !important;
}

.nav-link {
  padding: 0;
  margin-right: 1rem;
}

.nav-tabs {
  border: 0;
}

.input-group-text {
  font-size: inherit !important;
}

.react-datepicker-popper {
  z-index: 9999;
}

.dropdown-menu {
  font-size: inherit !important;
}

.recharts-surface, .recharts-legend-item-text {
  stroke: #8898aa !important;
  font-size: 0.8125rem;
}

.rdtPicker { 
  margin-top: 8px !important;
}

@media (max-width: 575.98px) {
  .rdtPicker {
    padding: 20px;
  }
}

// .rdt {
//   position: relative;

//   .rdtPicker {
//     -webkit-transition: all 150ms linear;
//     -moz-transition: all 150ms linear;
//     -o-transition: all 150ms linear;
//     -ms-transition: all 150ms linear;
//     transition: all 150ms linear;
//     margin-top: -20px;
//     visibility: hidden;
//     display: block;
//     opacity: 0;
//   }
//   &.rdtOpen {
//     .rdtPicker {
//       opacity: 1;
//       visibility: visible;
//       margin-top: 0;
//     }
//   }
// }
// .rdtPicker {
//   display: none;
//   position: fixed;
//   padding: 20px;
//   margin-top: 1px;
//   z-index: 99999 !important;
//   background: #fff;
//   border-radius: .375rem;
//   box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
//   -webkit-background-clip: padding-box;
//   background-clip: padding-box;
//   min-width: 160px;


// }

// .rdtPicker {
//   display: block;
//   top: 215px;
// }
// .rdtStatic .rdtPicker {
//   box-shadow: none;
//   position: static;
// }

.rdtPicker .rdtTimeToggle {
  text-align: center;
  padding: 10px;
  border-radius: 4px;
  visibility: hidden;
  position: relative;
  &:before {
    visibility: visible;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font: normal normal normal 1em/1 "Nucleo";
    font-size: 18px;
    content: "\EA5E";
  }
}

.rdtPicker table {
  width: 100%;
  margin: 0;
  border-color: $white !important;
}
.rdtPicker td,
.rdtPicker th {
  text-align: center;
  padding: 1px;
}
.rdtPicker td {
  cursor: pointer;
}
.rdtDay {
  font-size: .875rem;
  width: 36px;
  height: 36px;
  transition: all .15s ease;
  text-align: center;
  border: none;
  border-radius: 50%;
  &.rdtToday.rdtActive,
  &.rdtActive,
  &.rdtActive:hover,
  &.start-date,
  &.end-date,
  &.middle-date,
  &.start-date:hover,
  &.end-date:hover,
  &.middle-date:hover {
    background-color: $primary !important;
    color: $white !important;
  }
  &.start-date{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  &.middle-date{
    border-radius: 0;
  }
  &.end-date{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.rdtDays {
  tr {
    .dow {
      text-align: center;
      font-size: 12px;
      font-weight: inherit;
      padding-bottom: 5px;
      padding-top: 10px;
      font-weight: 300;
      font-size: .875rem;
      width: 20px;
      height: 20px;
    }
    .rdtDay {
      color: $gray-700;
      font-size: .875rem;
      &.rdtOld,
      &.rdtNew {
        color: $gray-500;
        font-weight: 300;
      }
    }
  }
}

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover,
.rdtPicker .rdtTimeToggle:hover:before {
  background: #eeeeee;
  cursor: pointer;
}
.rdtPicker td.rdtToday {
  position: relative;
}
.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker tr .dow {
  width: 14.2857%;
  border-bottom: none;
  font-size: .875rem;
  width: 36px;
  height: 36px;
  transition: all .15s ease;
  text-align: center;
  border: none;font-weight: 500;
  border-radius: .375rem;
}
.rdtPicker th.rdtSwitch {
  font-weight: 500;
  border-radius: .375rem;
  font-size: .875rem;
  width: 145px;
  height: 36px;
  transition: all .15s ease;
  text-align: center;
  border: none;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: .875rem;
  width: 36px;
  height: 36px;
  transition: all .15s ease;
  text-align: center;
  border: none;
  border-radius: .375rem;
  cursor: pointer;
}

.rdtPicker {
  .dow,
  th.rdtSwitch,
  th.rdtNext,
  th.rdtPrev,
  .rdtTimeToggle {
    color: $gray-700;
  }
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker thead tr:first-child th {
  cursor: pointer;
}
.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}
.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters {
  > div {
    float: left;
    width: 40px;
    font-weight: inherit;
    margin: 3px;
    border-radius: 50%;
  }
  .rdtCounterSeparator {
    width: 0;
    border: 1px solid transparent;
  }
}

.rdtCounter {
  height: 100px;
}

.rdtCounter {
  width: 40px;
  .rdtCount {
    padding: 7px;
    height: 40px;
    border: 1px solid transparent;
  }
}
.rdtCounters {
  .rdtCounter:last-child {
    .rdtCount {
      color: $primary;
      border-radius: 50%;
      border: 1px solid $primary;
    }
  }
}

.rdtCounterSeparator {
  padding: 7px;
  line-height: 100px;
}
.rdtCounter .rdtBtn {
  line-height: 40px;
  cursor: pointer;
  display: block;
  border-radius: 50%;
  color: $gray-500;
  -webkit-transition: all 60ms ease-in;
  -moz-transition: all 60ms ease-in;
  -o-transition: all 60ms ease-in;
  -ms-transition: all 60ms ease-in;
  transition: all 60ms ease-in;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.rdtCounter .rdtBtn:hover {
  background: #eee;
  color: #797979;
}
.rdtCounter .rdtCount {
  font-size: inherit;
  line-height: 25px;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: inherit;
  margin-top: 37px;
}
.rdtMonths,
.rdtYears {
  padding-bottom: 10px;
  .rdtMonth,
  .rdtYear {
    display: inline-block;
    width: 56px;
    height: 56px;
    line-height: 56px;
    margin: 3px 3px;
    cursor: pointer;
    border-radius: 50%;
    text-align: center;

    &.rdtActive,
    &.start-date,
    &.end-date,
    &.middle-date {
      background-color: $primary !important;
      color: $white;
    }
  }
}
.input-group .input-group-prepend + .rdt .form-control{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group .input-group-prepend + .rdt .form-control{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .rdt:not(:last-child) .form-control{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.form-group .rdt {
  width: 1%;
  margin-bottom: 0;
  flex: 1 1 auto;
}

.rdt input {
  border: 0px !important;
  outline: none !important;
  box-shadow: none !important;
  width: 100%;
  cursor: pointer;
}

.rdt .form-control[readonly] {
  background-color: transparent !important;
}

.rdt .form-control[disabled] {
  background-color: $input-disabled-bg !important;
}

.nav-item {
  font-weight: normal;
  font-size: 14px;
}

.text-opaque {
  opacity: 0.5;
}

.navbar {
  padding: .5rem 1rem;
}

.nav-tabs .nav-item .nav-link:hover {
  border-color: rgba(0,0,0,0);
  cursor: pointer;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active:hover {
  color: $primary;
  border: 0px;
  border-bottom: 2px solid $primary;
}

.nav-tabs .nav-link.active{
  background: none;
  padding-bottom: 2.5px;
}

.nav-tabs .nav-item {
  color: $light;
  border: 0px;
}

.dropdown-item {
  cursor: pointer;
}

button.dropdown-item:hover, button.dropdown-item:active {
  color: black;
}

#sidebar-wrapper {
  height: 100% !important;
  min-height: 100vh !important;
  color: $primary;
  width: 200px;
  background-color: white;
  -webkit-transition:  .5s ease-out;
  -moz-transition:  .5s ease-out;
  -o-transition:  .5s ease-out;
  transition:  .5s ease-out;
  position: relative;
  z-index: 10;
  font-size: 0.85rem;
}

#sidebar-wrapper .list-group-item {
  background-color: inherit;
  border: 0;
  color: $gray-600;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: 400;
  display: flex;
  align-items: center;
  padding: 10px 35px;
  transition: 0.1s linear;
}

#sidebar-wrapper .list-group-item:hover {
  color: $primary;
}

#sidebar-wrapper .active {
  background-color: rgba($primary, 0.05);
  border-left: 3px solid $primary;
  color: $primary;
  font-weight: 600;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
  text-align: center;
}

#sidebar-wrapper .sidebar-heading h3 { 
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
}

#page-content-wrapper {
  min-width: 100vw;
    -webkit-transition:  .5s ease-out;
  -moz-transition:  .5s ease-out;
  -o-transition:  .5s ease-out;
  transition:  .5s ease-out;
}

#sidebar-wrapper svg {
  position: relative;
  float: right;
  font-size: 2rem;
  cursor: pointer;
}

.dropright .dropdown-toggle::after {
  border: none !important
}

#sidebar-wrapper .list-group-item:first-child,
#sidebar-wrapper .list-group-item:last-child {
  border-radius: 0;
}

@media (max-width: 979px) {
  .toggled > #sidebar-wrapper  {
    margin-left:-200px;
  }

  #sidebar-wrapper  {
    margin-left: 0;
    position: fixed;
  }

  #page-content-wrapper {
    padding-left: -200px;
  }
}

@media (min-width: 979px) {
  #sidebar-wrapper {
    margin-left: -230px;
  }
  
  #sidebar-wrapper .sidebar-heading h3 {
    justify-content: center;
  }

  .toggled > #sidebar-wrapper  {
    margin-left: 0;
  }

  #sidebar-wrapper .sidebar-heading svg {
    display: none;
  }

  #page-content-wrapper {
    padding-left: -200px;
    margin-left: -200px;
  }

  #page-content-wrapper.toggled {
    padding-left: 200px;
  }
}

.react-datepicker__time-container,
.react-datepicker__time-box { 
  width: 100% !important;
}

.react-datepicker__input-container input {
  border: 0px !important;
  outline: none !important;
  box-shadow: none !important;
  max-width: 100%;
  cursor: pointer;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container input:focus {
  outline: none !important;
}

.react-datepicker--time-only {
  min-width: 10rem;
  margin: .125rem 0 0;
  font-size: 1rem;
  color: #525f7f;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0,0,0,.15);
  border-radius: .4375rem;
  box-shadow: 0 50px 100px rgba(50,50,93,.1), 0 15px 35px rgba(50,50,93,.15), 0 5px 15px rgba(0,0,0,.1);
}

@media (max-width: 575px) {
  .react-datepicker--time-only {
    min-width: auto;
  }
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__time-list-item {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem !important;
  clear: both;
  font-weight: 400 !important;
  height: auto !important;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.react-datepicker__header--time,
.react-datepicker__time-list-item--disabled {
  display: none !important;
}

.react-datepicker {
  font-family: inherit !important;
  font-size: .875rem;
}

.react-datepicker__time-list-item--selected {
  background-color: $primary !important;
}

p {
  font-size: $font-size-sm !important;
  font-weight: $font-weight-normal !important;
}

th {
  span.sort-by{
    padding-left: 5px;
    position: relative;
    color: $gray-400;
  }

  span.sort-by:before {
    content: "\2191";
  }

  span.sort-by:after {
    content: "\2193";
  }

  span.sort-by.desc:before {
    color: $gray-400
  }

  span.sort-by.desc:after {
    color: $gray-800
  }

  span.sort-by.asc:before {
    color: $gray-800
  }

  span.sort-by.asc:after {
    color: $gray-400
  }
}

.wrap-cell {
  white-space: normal !important; 
  word-wrap: break-word;  
  min-width: 200px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number]{
  -moz-appearance: textfield;
}

tbody tr:hover {
  background-color: #f6f9fc;
  cursor: pointer;
}

tr {
  transition: all .15s ease;
}

.border-danger {
  border: 1px $danger solid !important;
  border-radius: 0.25em;
}

.ql-snow .ql-picker {
  color: #444;
  /* display: inline-block; */
  float: left;
  font-size: 14px;
  font-weight: 500;
  height: 24px;
  position: relative;
  vertical-align: middle;
}

.ql-container.ql-snow {
  border: 0px;
}

.ql-toolbar.ql-snow {
  border: none;
  background-color: lighten($gray-200, 3%);
}

.quill {
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  border: 0;
  transition: box-shadow .15s ease;
  border-radius: 0.25rem;
}

.ql-editor {
  border: none;
  height: 450px;
}

.ql-h100 .ql-editor {
  height: 100%
}

.tall-modal .modal-content {
  max-height: 90vh;
  overflow: auto;
}

.Toastify {
  height: 0px !important;
}

.form-control-label {
  color: $default;
}

.blur {
  -webkit-filter: blur(2.5px);
  -moz-filter: blur(2.5px);
  -o-filter: blur(2.5px);
  -ms-filter: blur(2.5px);
  filter: blur(2.5px);
}

.spinner-over-blur {
  margin: auto;
  position: absolute;
  height: 40px;
  top: 20%;
  left: 0;
  right: 0;
}

.rbc-time-gutter {
  z-index: 0 !important;
}

.modal-footer.light {
  background-color: #F0F3F5;
}

.text-default {
  color: $gray-800 !important;
}

.badge-primary {
  color: #fff;
  background-color: $primary;
}

.badge-danger {
  color: #fff;
  background-color: $danger;
}

.badge-success {
  color: #fff;
  background-color: $success;
}

.badge-default {
  color: #fff;
  background-color: $default;
}

.badge-info {
  color: #fff;
  background-color: rgba($default, 0.5);
}

.react-datepicker--time-only .react-datepicker__time,
.react-datepicker--time-only .react-datepicker__time-box {
  border-radius: 0.3rem;
}

.rdt.rdt-special {
  width: 292px !important;
}

.rdt.rdt-special input {
  text-align: center !important;
  width: 292px !important;
  background-color: transparent;
  font-weight: 500;
  color: $gray-800 !important;
}

.rdt.rdt-special .rdtPicker {
  top: auto;
}

@include media-breakpoint-down(sm) {
  .custom-padding {
    padding-bottom: 20px;
  }
}

.form-control {
  height: calc(1.125em + 0.9375rem + 7px);
  -webkit-appearance: none !important;
  cursor: pointer;
}

.input-group-text {
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.log-table {
  font-size: 0.875rem;

  tr {
    background-color: inherit !important;
    cursor: default !important;
  }

  td {
    padding-bottom: 0.5rem;
  }

  tr:last-child td {
    padding-bottom: 0rem;
  }

  td:nth-child(1) {
    font-weight: 600;
    color: $default;
    display: flex;
    align-items: center;
  }

  td:nth-child(2) {
    padding-left: 3rem;
    font-weight: normal;
  }
}

.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.1s linear;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.15s, opacity 0.15s linear;
}

.quill.quill-disabled {
  box-shadow: none;
}

.quill-disabled {
  .ql-toolbar {
    display: none;
  }

  .ql-editor {
    padding: 0px;
  }
}

.navbar-mobile {
  box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.15), 0 1px 0 rgba(0, 0, 0, 0.05) !important;
  
  a {
    color: $gray-600;
    font-size: $font-size-sm * 0.95;

    svg {
      color: $gray-500;
      margin-bottom: 2.5px;
    }
  }

  a.active {
    color: $primary;

    svg {
      color: $primary;
    }
  }
}

.logs-table .table {
  tbody tr:hover{
    background-color: inherit;
  }

  tbody tr:nth-of-type(odd) {
    background-color: rgba($primary, 0.03);
  }
}

.wrap-cell {
  white-space: normal !important; 
  word-wrap: break-word;  
}

.table th, .table td {
  vertical-align: middle;
}

.table th {
  font-weight: 500;
}

.rbc-event:focus {
  outline: none;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: $gray-100;
}

.table-striped tbody tr:nth-of-type(odd):hover {
  background-color: $gray-100;
}

.table-striped tbody tr:nth-of-type(even):hover {
  background-color: white;
}