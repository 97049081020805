@font-face {
  font-family: 'Aeonik';
  src: url('../fonts/Aeonik-Bold.otf') format('opentype');
  font-weight: 700; /* Bold */
  font-style: normal;
}

@font-face {
  font-family: 'Aeonik';
  src: url('../fonts/Aeonik-Bold-Italic.otf') format('opentype');
  font-weight: 700; /* Bold */
  font-style: italic;
}

@font-face {
  font-family: 'Aeonik';
  src: url('../fonts/Aeonik-Medium.otf') format('opentype');
  font-weight: 500; /* Medium */
  font-style: normal;
}

@font-face {
  font-family: 'Aeonik';
  src: url('../fonts/Aeonik-Medium-Italic.otf') format('opentype');
  font-weight: 500; /* Medium */
  font-style: italic;
}

@font-face {
  font-family: 'Aeonik';
  src: url('../fonts/Aeonik-Light.otf') format('opentype');
  font-weight: 300; /* Light */
  font-style: normal;
}

@font-face {
  font-family: 'Aeonik';
  src: url('../fonts/Aeonik-Light-Italic.otf') format('opentype');
  font-weight: 300; /* Light */
  font-style: italic;
}

@font-face {
  font-family: 'Aeonik';
  src: url('../fonts/Aeonik-Regular.otf') format('opentype');
  font-weight: 400; /* Normal */
  font-style: normal;
}

@font-face {
  font-family: 'Aeonik';
  src: url('../fonts/Aeonik-Regular-Italic.otf') format('opentype');
  font-weight: 400; /* Normal */
  font-style: italic;
}

@font-face {
  font-family: 'Aeonik';
  src: url('../fonts/Aeonik-Thin.otf') format('opentype');
  font-weight: 100; /* Thin */
  font-style: normal; /* Assuming this should be normal, adjust if italic was intended */
}

@font-face {
  font-family: 'Aeonik';
  src: url('../fonts/Aeonik-Thin-Italic.otf') format('opentype');
  font-weight: 100; /* Thin */
  font-style: italic;
}
